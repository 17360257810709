
import { defineComponent, reactive, ref, PropType, watch, computed } from '@vue/composition-api'
import { cloneDeep } from 'lodash-es'

import CommonAddEditDialog from '@/components/common/CommonAddEditDialog.vue'
import CommonDateInput from '@/components/common/CommonDateInput.vue'
import CommonNumberInput from '@/components/common/CommonNumberInput.vue'
import CommonAutocomplete from '@/components/common/CommonAutocomplete.vue'

import { useNotify } from '@/store'

import { isRequired } from '@/utils/validation'
import { isOfType } from '@/utils/types/isOfType'
import { convertCommaIntoDotInputWithForm, convertDotIntoCommaInputWithForm } from '@/utils/convertInput'
import { dateDotNotationToDashWithForm, dateDashNotationToDotWithForm } from '@/utils/convertDate'
import { handleError } from '@/utils/handleError'
import { mapBasicEntityToIdWithForm } from '@/utils/mapBasicEntityToIdWithForm'

import { useCreateInventoryItem, useUpdateInventoryItem } from '@/api/inventoryItem'

import { InventoryItemOutput, InventoryItemInput } from '@/api/types/inventoryItem'

import { DATA_TYPE, FormField, FORM_FIELDS_ENUM } from '@/utils/types/formField'
import { useGetOrgUnitsBasic } from '@/api/orgUnit'

export default defineComponent({
  name: 'AddEditInventoryItemDialog',
  components: {
    CommonAddEditDialog,
    CommonNumberInput,
    CommonDateInput,
    CommonAutocomplete,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },

    inventoryItemToEdit: {
      type: Object as PropType<InventoryItemOutput>,
      default: null,
    },
  },
  setup: (props, { root, emit }) => {
    const { addNotification } = useNotify()

    const isEditMode = computed(() => Boolean(props.inventoryItemToEdit))

    const { exec: getOrgUnitsBasic, data: orgUnits, isLoading: isLoadingOrgUnitsBasic } = useGetOrgUnitsBasic()
    getOrgUnitsBasic()

    const FORM_FIELDS: FormField[] = [
      {
        value: 'name',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('baseData.inventoryItems.form.name') as string)],
      },
      {
        value: 'type',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
      {
        value: 'serialNumber',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
      {
        value: 'description',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
      {
        value: 'inService',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.DATE,
        isRequired: false,
        rules: [],
      },
      {
        value: 'outOfService',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.DATE,
        isRequired: false,
        rules: [],
      },
      {
        value: 'purchasePrice',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.NUMBER,
        isRequired: false,
        rules: [],
      },
      {
        value: 'organizationalUnit',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => orgUnits.value ?? []),
        isLoading: computed(() => isLoadingOrgUnitsBasic.value),
        isRequired: false,
        rules: [],
        dropdownTextProp: 'name',
      },
    ]

    const form = ref<InventoryItemInput | InventoryItemOutput>(
      isEditMode.value ? cloneDeep(props.inventoryItemToEdit) : ({} as InventoryItemInput)
    )

    const { createInventoryItem, isLoading: isLoadingCreateInventoryItem } = useCreateInventoryItem()

    async function onAdd(): Promise<void> {
      if (!isOfType<InventoryItemOutput>(form.value, 'id')) {
        try {
          let updatedForm = convertCommaIntoDotInputWithForm(FORM_FIELDS, form.value)

          updatedForm = dateDotNotationToDashWithForm(FORM_FIELDS, updatedForm)

          await createInventoryItem(updatedForm)

          close()

          addNotification({
            text: root.$t('baseData.inventoryItems.create.message') as string,
            type: 'success',
          })
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const { updateInventoryItem, isLoading: isLoadingUpdateInventoryItem } = useUpdateInventoryItem()

    async function onEdit(): Promise<void> {
      if (isOfType<InventoryItemOutput>(form.value, 'id')) {
        let updatedForm = mapBasicEntityToIdWithForm<InventoryItemOutput>(form.value)

        updatedForm = convertCommaIntoDotInputWithForm(FORM_FIELDS, updatedForm)

        updatedForm = dateDotNotationToDashWithForm(FORM_FIELDS, updatedForm)

        try {
          await updateInventoryItem(updatedForm.id, updatedForm)

          close()

          addNotification({
            text: root.$t('baseData.inventoryItems.edit.message') as string,
            type: 'success',
          })
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const isLoadingAddEditInventoryItem = computed(
      () => isLoadingCreateInventoryItem.value || isLoadingUpdateInventoryItem.value
    )

    watch(
      () => props.inventoryItemToEdit,
      () => {
        if (props.inventoryItemToEdit) {
          form.value = cloneDeep(props.inventoryItemToEdit)

          form.value = convertDotIntoCommaInputWithForm(FORM_FIELDS, form.value)

          form.value = dateDashNotationToDotWithForm(FORM_FIELDS, form.value)
        }
      },
      {
        immediate: true,
      }
    )

    function close(): void {
      emit('added-edited')
      emit('close')
    }

    return reactive({
      constants: {
        FORM_FIELDS_ENUM,
        DATA_TYPE,

        FORM_FIELDS,
      },
      state: {
        isEditMode,

        form,

        isLoadingAddEditInventoryItem,
      },
      listeners: {
        onAdd,

        onEdit,
      },
      functions: {
        formRules: {
          isRequired,
        },
      },
    })
  },
})
